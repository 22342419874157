html {
    height: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

.height-100 {
    height: 100vh;
}

.button-back {
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
    cursor: pointer;
}

@media only screen and (max-width: 1194px) {
    .button-back {
        text-align: center;
        font-size: 20px;
    }
}

.column-left {
    /* background-color: #F5F5F5; */
    background-color: #fff;
}

@media only screen and (max-width: 770px) {
    .column-left {
        display: none;
    }
}

.form-right {
    margin-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
}

.button-link-custom {
    background-color: #265D26 !important;
}

.node-snackbar-custom {
    border-radius: 0px !important;
    font-weight: bold !important;
    margin-left: 5px;
    margin-right: 5px;
}

@media (max-width: 640px) {
    .snackbar-container {
        margin-bottom: 3px;
        left: 0;
        right: 0;
        width: 100%;
    }
}

.button {
    border-radius: 0.8rem !important;
}

.button.is-small {
    border-radius: 0.6rem !important;
}

.input {
    border-radius: 0.7rem !important;
}

select {
    border-radius: 0.7rem !important;
}

textarea {
    border-radius: 0.7rem !important;
}